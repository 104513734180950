//
//
//
//
//
//
//


import domel from '~/components/domel'
import RestModelDummy from '~/components/rest-model-dummy'
import RestImgDummy from '~/components/rest-img-dummy'
import RestStringDummy from '~/components/rest-string-dummy'
import RestStringLinkDummy from '~/components/rest-string-link-dummy'
import RestPropDummy from '~/components/rest-prop-dummy'
import RestPropLinkDummy from '~/components/rest-prop-link-dummy'
import RestDateDummy from '~/components/rest-date-dummy'
import RestRichTextDummy from '~/components/rest-rich-text-dummy'
// import RestReorderListDummy from '~/components/rest-reorder-list-dummy'
import CmsEditDummy from '~/components/cms-edit-dummy'
// import CmsEditButton from '~/components/cms-edit-button-dummy'
import Quotes from '~/components/quotes-edit'

/*
<router>
{
	path: '/:lang/corporate',
	alias: [
		'/:lang/something',
		// '/blog'
	]
}
</router>
*/


import page from "./corporate.js"

let components = page.components;
// delete page.components;

export default {
	name: 'corporate-index',

	...page,

	layout: 'corporate',

	components:
	{
		...components,

		'rest-img': RestImgDummy,// RestImg,
		'rest-prop': RestPropDummy, // RestProp,
		'rest-rich-text': RestRichTextDummy,// RestRichText,
		'rest-model': RestModelDummy,// RestModel,
		'rest-link': RestPropLinkDummy, // 'rest-link': RestPropLink,
		'rest-string': RestStringDummy, // RestString,
		'rest-string-link': RestStringLinkDummy,// RestStringLink,
		'rest-date': RestDateDummy, // RestDate,
		'rest-date-add': domel,// RestDateAdd,
		// 'rest-reorder-list': RestReorderListDummy,//RestReorderList,
		'rest-list-item-add': domel,// RestListItemAdd,
		'rest-item-add': domel,// RestItemAdd,
		'rest-item-removable': domel,// RestItemRemovable,
		'tag-input': domel,// TagInput,
		'cms-edit': CmsEditDummy,
		Quotes,
		// CmsEditButton,
	}
}

