
// import 'hooper/dist/hooper.css'
import Vue from 'vue'
const consola = require('consola'); consola.level = process.env.CONSOLA_LEVEL;
import { mapGetters, mapMutations, mapActions } from 'vuex'
import TextFx from '~/components/text-fx'
import HeroSection from '~/components/hero-section'
import svgSymbol from '~/components/svg-symbol'
import ctaArrow from '~/components/cta-arrow'
import Carousel from '~/components/carousel'
import capitalize from '~/src/utils/capitalize'
import HeaderSidebar from '~/components/header-sidebar'
import Trustmarks from '~/components/trustmarks'
import StatsBox from '~/components/stats-box'

// import CmsEditButton from '~/components/cms-edit-button'
// import Quotes from '~/components/quotes'

import { setTimeout } from 'timers'

import Intersect from '~/components/vue-intersect'
import ScrollObserver from '~/components/scroll-observer-raf'
import { Portal, PortalTarget } from 'portal-vue'

import HeaderMetaMixin from '~/mixins/header-meta'
import AnnouncementsMixin from '~/mixins/announcements'
import InstructorModalMixin from '~/mixins/open-instructor-modal'
import CourseDateUtils from '~/mixins/course-date-utils'

import LazyHydrate from 'vue-lazy-hydration';

// import '~/assets/styles/conversation-form.sass'

const endsWith = require('lodash.endswith')

// this is how you can get the path of an image asset
// import pinSvg from '~/assets/svg/pin.svg'

// import yamlData from '~/assets/test.yaml'
// const page_slug = '/';

export default {

	layout: 'corporate',

	mixins: [HeaderMetaMixin, CourseDateUtils, AnnouncementsMixin, InstructorModalMixin],

	components:
	{
		LazyHydrate,

		// you *MUST* declare components before you can use them
		// 'rest-img': RestImgDummy,// RestImg,
		// 'rest-prop': RestPropDummy, // RestProp,
		// 'rest-rich-text': RestRichTextDummy,// RestRichText,
		// 'rest-model': domel,// RestModel,
		// 'rest-link': RestPropLinkDummy, // 'rest-link': RestPropLink,
		// 'rest-string': RestStringDummy, // RestString,
		// 'rest-string-link': RestStringLinkDummy,// RestStringLink,
		// 'rest-date': RestDateDummy, // RestDate,
		// 'rest-date-add': domel,// RestDateAdd,
		// 'rest-reorder-list': RestReorderListDummy,//RestReorderList,
		// 'rest-list-item-add': domel,// RestListItemAdd,
		// 'rest-item-add': domel,// RestItemAdd,
		// 'rest-item-removable': domel,// RestItemRemovable,
		// 'tag-input': domel,// TagInput,

		HeaderSidebar,
		// CmsEditButton,
		// ScrollHint,

		Portal,
		PortalTarget,

		Intersect,
		ScrollObserver,

		// Affix,

		ctaArrow,
		svgSymbol,
		TextFx,
		HeroSection,
		Carousel,
		Trustmarks,
		StatsBox,
		// Quotes,
		// Accordion
	},

	// this overrides the HeadMeatMixin
	// head: { bodyAttrs: { "class": ["page-corporate"] } },


	data()
	{
		return {
			host: null,

			bodyClasses: ["page-corporate"],

			pageLoaded: false,
			pageData: {},
			blocks: [{}, {}, {}, {}, {}, {}, {}, {}, {}, ],

			currentSectionKey: 'key-benefits',

			animsOnce: true,

			heroSubtitleStyle: {
				opacity: 0
			},

			sidebarSubtextStyle: {
				transition: 'none',
				opacity: 0,
			},

			sidebarClasses: ['Head-Sidebar--hide-left'],

			affixTopOffset: 160,
			// affixTop: 0,
			affixStyle: { top: 0 },
			isAffixFixed: false,
			affixYPercent: 0,
			affixSlideIndex: 0,
			isAffixAtBottom: false,
			isAffixTransitioning: false,
			// cfForm: null,

			quoteIsVisible: false,
			quoteIsTransitioning: false,
			quoteTransitionLeaving: false,
			quoteTransitionEntering: false,

			// openingQuoteForm: false,
			// showQuoteForm: false,

			selectedUpdatesCatId: -1,
			updatesCategories: [],

			selectedPartnerCatId: -1,
			partnersCategories: [],

			selected_reason_idx: -1,
			selected_number_stat: 0,

			resizeCallback: null,


			// expertise: [
			// 	{ title: 'Translation' },
			// 	{ title: 'Interpretation' },
			// 	{ title: 'Localization' },
			// 	{ title: 'Subtitling' },
			// 	{ title: 'Editing & Proofreading' },
			// 	{ title: 'Quality Assessment' },
			// ],

			selected_partners_cat: '',
			partners: [
			],

			currentQuoteIdx: 0,

			selected_update_cat: '',
			updates: [
			],

			isEditingPage: false,
		};
	},


	async asyncData({app, params, store, error, req, route})
	{
		try {
			// consola.debug('route: ', route)
			// consola.debug('req: ', req)
			// const result = await app.$apiGet('page-slug/'+params.slug, { locale: store.getters.lang } )
			store.dispatch('setLang', { lang: params.lang });
			store.dispatch('setDeviceWidthFromUserAgent');

			let page_slug = route.path.replace('/edit','').slice(3);
			page_slug = page_slug || '/';
			consola.debug('corporate slug: ', page_slug);

			let result = null;
			// debugger;

			try
			{
				// result = await app.$apiGet('page-slug/corporate', { locale: params.lang } )
				result = await app.$apiGet('page-slug/', { slug: page_slug, site_id: 1, locale: params.lang } )
			}
			catch (err)
			{
				// debugger;
				// consola.debug(err);
				return error({ statusCode: err.response && err.response.status || err.code || '', message: err.message })
			}
			// const result = await app.$apiLoadCourseData('masterclass-in-translation')

			// const updateCatResult = await app.$apiGetTaxonomy('UPDATE_CATEGORIES');
			// const partnerCatResult = await app.$apiGetTaxonomy('PARTNERSHIP_CATEGORIES');

			// consola.debug('partnerCatResult.data: ', partnerCatResult.data)

			// var updateCategories = [];
			// if (updateCatResult && updateCatResult.data)
			// 	for (let item of updateCatResult.data)
			// 		updateCategories.push(item);

			// var partnerCategories = [];
			// if (partnerCatResult && partnerCatResult.data)
			// 	for (let item of partnerCatResult.data)
			// 		partnerCategories.push(item);

			// consola.debug('partnerCategories: ', partnerCategories)

			let host = null;
			if (process.server)
			{
				host = req.headers.host;
			}
			else
			{
				host = window.location.host;
			}

			// debugger;

			// consola.debug('typeof result: ', typeof result);
			consola.debug('Object.keys(result): ', Object.keys(result) );
			consola.debug('result.status: ', result.status );
			consola.debug('result.statusText: ', result.statusText );
			consola.debug('params.slug: ', params.slug);
			// consola.debug('result: ', result);

			if (result.data[0].locale)
			{
				consola.debug('page locale: ',result.data[0].locale )
				store.commit('set_lang', { lang: result.data[0].locale })
			}

			if (result.data[0].course)
				for (let item of result.data[0].course.dates)
					item.delete = false;

			// Load the edit button
			// if (process.client) window.prismic.setupEditButton()

			// if (result.status != 200)
			// {
			// 	// this does *NOT* work. it still tries to render the page template
			// 	error({ statusCode: result.status, message: result.statusText })
			// 	redirect(result.statusCode, '/')
			// 	// return ;
			// }
			// else
			const pageData = result.data[0];

			if (pageData && pageData.site)
				store.commit('setSiteData', pageData.site)

			const menu = pageData.menu && pageData.menu.json || [];
			store.commit('setPageMenu', menu);

			const footer = pageData.footer && pageData.footer.json || [];
			store.commit('setPageFooter', footer);

			// if (pageData && pageData.site && pageData.site.mobile_menu)
			// 	store.commit('setMobileMenuData', pageData.site.mobile_menu)

			// if (pageData && pageData.site && pageData.site.menu)
			// 	store.commit('setMenuData', pageData.site.menu)

			// if (pageData && pageData.site && pageData.site.footer)
			// 	store.commit('setFooterData', pageData.site.footer)

				consola.debug('taxonomies: ', pageData.taxonomies)
				consola.debug('blocklist count: ', pageData.block_list.length)
				// consola.debug('asyncData blocks[10].extra_data: ', result.data[0].block_list[10].extra_data)
				return {
					pageData,
					host,
					blocks: pageData.block_list,
					partnersCategories: [],//partnerCategories,
					updatesCategories: [],//updateCategories,
				}
		}
		catch (e)
		{
			consola.error(`${__filename}[189] error: `, e)
			// error({ statusCode: 404, message: 'Page not found' })
			// error({ statusCode: 404, message: e })
		}
	},

	watch:
	{
		pageLoaded(newVal)
		{
			setTimeout( ()=>
			{
				if (newVal)
					this.sidebarClasses = [];
			},
			1000 )
		},

		pageData(newVal)
		{
			if (!newVal)
				return;

			this.blocks = newVal.block_list
			consola.debug('pageData: ', newVal)

			let menu = newVal.menu && newVal.menu.json || [];
			this.$store.commit('setPageMenu', menu);

			const footer = newVal.footer && newVal.footer.json || [];
			this.$store.commit('setPageFooter', footer);

			this.calcUpdatesCategories();
			this.calcPartnershipCategories();
			this.$meta().refresh();
		},

		lang(newVal, prevVal)
		{
			consola.debug(`language was changed to [${newVal}], retrieving new data...`)
		},

		partnersCategories(newVal)
		{
			consola.debug('partnersCategories changed to ', newVal);
		},

		isAffixFixed(newVal)
		{
			if (this.isEditingPage)	return;
			if (newVal)
			{
				this.$refs.stats.parentNode.removeChild( this.$refs.stats );
				document.body.appendChild( this.$refs.stats )
			}
			else
			{
				if (this.$refs.stats.parentNode)
					this.$refs.stats.parentNode.removeChild( this.$refs.stats );
				document.querySelector('.affix-rails').appendChild( this.$refs.stats )
			}
		},

		// selected_reason_idx(newVal)
		// {
		// 	this.sidebarSubtextStyle.transition = 'none';
		// 	this.sidebarSubtextStyle.opacity = 0;

		// 	this.$gtag && this.$gtag.event( 'click', { event_category:'Why el-translations Tab', event_label:  'tab'+(newVal+1), value:  1 });

		// 	setTimeout( () =>
		// 	{
		// 		this.sidebarSubtextStyle.transition = 'opacity 0.5s ease-in-out 1.0s';
		// 		this.sidebarSubtextStyle.opacity = 0;
		// 	}, 10)

		// 	setTimeout( () =>
		// 	{
		// 		// this.sidebarSubtextStyle.transition = 'opacity 0.3s ease-in-out 0.5s';
		// 		this.sidebarSubtextStyle.opacity = 1;
		// 	}, 50)
		// },

		// windowWidth(newVal, prevVal)
		// {
		// 	consola.debug('windowWidth: ', newVal);
		// 	// if (prevVal < 1030 && newVal > prevVal )
		// 	// {
		// 	// 	debugger;
		// 	// }
		// }
	},

	computed:
	{
		...mapGetters([/*'isEditingPage',*/ 'getString', 'lang', 'langShort', 'allLanguages', 'adminPanelRef', 'windowWidth', 'isMobile', 'isTablet', 'isTabletOrBigger', 'isLargerThanTabletLandscape', 'isLaptop', 'isLaptopOrBigger', 'isDesktop', 'windowWidth', 'scrollTop']),

		// reason()
		// {
		// 	if ( !this.blocks || !this.blocks[0].extra_data )
		// 		return []
		// 	if (this.selected_reason_idx < 0)	return '';
		// 	return this.blocks[0].extra_data[this.selected_reason_idx]
		// 	// return this.reasons[this.selected_reason_idx];
		// },

		corpHeading()
		{
			const label = "corporate-heading"; var ret = {};
			try { ret = this.blocks.filter(x => x.block_type == label)[0]; }
			catch(error) { console.error(`block[${label}] not found`);  }
			return ret;
		},

		// heroSidebar()
		// {
		// 	const label = "hero-sidebar"; var ret = {};
		// 	try { ret = this.corpHeading.extra_data.filter(x => x.block_type == label)[0]; }
		// 	catch(error) { console.error(`block[${label}] not found`);  }
		// 	return ret;
		// },

		// solutions()
		// {
		// 	const label = "solutions"; var ret = {};
		// 	try { ret = this.corpHeading.extra_data.filter(x => x.block_type == label)[0]; }
		// 	catch(error) { console.error(`block[${label}] not found`);  }
		// 	return ret;
		// },

		servicesBlock()
		{
			const label = "services"; var ret = {};
			try { ret = this.blocks.filter(x => x.block_type == label)[0]; }
			catch(error) { console.error(`block[${label}] not found`);  }
			return ret;
		},

		expertiseBlock()
		{
			const label = "expertises"; var ret = {};
			try { ret = this.blocks.filter(x => x.block_type == label)[0]; }
			catch(error) { console.error(`block[${label}] not found`);  }
			return ret;
		},

		speakBlock()
		{
			const label = "speak"; var ret = {};
			try { ret = this.blocks.filter(x => x.block_type == label)[0]; }
			catch(error) { console.error(`block[${label}] not found`);  }
			return ret;
		},

		stickyScrollerBlock()
		{
			const label = "sticky-scroller"; var ret = {};
			try { ret = this.blocks.filter(x => x.block_type == label)[0]; }
			catch(error) { console.error(`block[${label}] not found`);  }
			return ret;
		},

		clientTrustmarksBlock()
		{
			const label = "client-trustmarks"; var ret = {};
			try { ret = this.blocks.filter(x => x.block_type == label)[0]; }
			catch(error) { console.error(`block[${label}] not found`);  }
			return ret;
		},

		aboutBlock()
		{
			const label = "about-us"; var ret = {};
			try { ret = this.blocks.filter(x => x.block_type == label)[0]; }
			catch(error) { console.error(`block[${label}] not found`);  }
			return ret;
		},

		quotesBlock()
		{
			const label = "quotes"; var ret = {};
			try { ret = this.blocks.filter(x => x.block_type == label)[0]; }
			catch(error) { console.error(`block[${label}] not found`);  }
			return ret;
		},

		careerBlock()
		{
			const label = "career-opportunities"; var ret = {};
			try { ret = this.blocks.filter(x => x.block_type == label)[0]; }
			catch(error) { console.error(`block[${label}] not found`);  }
			return ret;
		},

		instituteBlock()
		{
			const label = "institute"; var ret = {};
			try { ret = this.blocks.filter(x => x.block_type == label)[0]; }
			catch(error) { console.error(`block[${label}] not found`);  }
			return ret;
		},

		updatesBlock()
		{
			const label = "updates"; var ret = {};
			try { ret = this.blocks.filter(x => x.block_type == label)[0]; }
			catch(error) { console.error(`block[${label}] not found`);  }
			return ret;
		},

		partnersBlock()
		{
			const label = "partnerships"; var ret = {};
			try { ret = this.blocks.filter(x => x.block_type == label)[0]; }
			catch(error) { console.error(`block[${label}] not found`);  }
			return ret;
		},

		quote()
		{
			if (!this.blocks[7].extra_data || this.blocks[7].extra_data.length == 0)
				return {};
			return this.blocks[7].extra_data[this.currentQuoteIdx];
			// return this.quotes[this.currentQuoteIdx];
		},

		filteredPartners()
		{
			let result = null;
			if (this.selectedPartnerCatId && this.selectedPartnerCatId != -1)
			{
				// const cat = this.selected_update_cat.toLowerCase()
				result = this.blocks[10].extra_data.filter( item =>
					{
						if ( !item.taxonomies )	return false;

						const found = item.taxonomies.find( x => x.taxonomy_id == this.selectedPartnerCatId )
						return !!found;
					})
			}
			else
			{
				result = this.blocks[10].extra_data
			}

			// const rnd = Math.round(Math.random()*10000)
			// result.forEach((x, idx) => x.order = rnd + idx)

			// consola.debug(`filteredUpdates order: ${result.map(x => x.order)} `, result)

			return result
		},

		filteredUpdates()
		{
			let result = null;
			if (this.selectedUpdatesCatId && this.selectedUpdatesCatId != -1)
			{
				// const cat = this.selected_update_cat.toLowerCase()
				result = this.blocks[8].extra_data.filter( item =>
					{
						if ( !item.taxonomies )	return false;

						const found = item.taxonomies.find( x => x.taxonomy_id == this.selectedUpdatesCatId )
						return !!found;
					})
			}
			else
			{
				result = this.blocks[8].extra_data
			}

			// const rnd = Math.round(Math.random()*10000)
			// result.forEach((x, idx) => x.order = rnd + idx)

			// consola.debug(`filteredUpdates order: ${result.map(x => x.order)} `, result)

			return result
		},

		// updatesCategories()
		// {
		// 	let list = ['All']
		// 	let dict = {}
		// 	this.updates.forEach(x => {
		// 		const cat = capitalize(x.cat)
		// 		if (dict[cat]) return
		// 		list.push( cat )
		// 		dict[ cat ] = true
		// 	})

		// 	return list
		// }

		// updatesBlock()
		// {
		// 	return this.blocks ? this.blocks[8] : {};
		// },

		// partnersBlock()
		// {
		// 	return this.blocks ? this.blocks[10] : {};
		// },
	},

	beforeMount()
	{
		this.setWhiteLogo(true);
	},

	// this is called when the children have been mounted and the component is ready
	mounted()
	{
		consola.debug('blocks: ', this.blocks)
		consola.debug('updatesBlock: ', this.updatesBlock)
		consola.debug('partnersBlock: ', this.partnersBlock)
		// this.$apiLoadCourseData('masterclass-in-translation').then((result)=>
		// {
		// 	consola.debug('result: ', result);
		// })
		// .catch((error)=>
		// {
		// 	console.error(error);
		// })

		// this.$store.dispatch('setDeviceWidthFromWindow');

		if ( typeof window !== 'undefined' )
		{
			let menuEl = document.querySelector('.Menu--wrapper');
			if (menuEl)
			{
				let box = menuEl.getBoundingClientRect();
				this.affixTopOffset = box.height;
			}

			// this.cfForm = require('conversational-form');
			// this.setupFeeeQuoteForm();
		}


		this.registerEvents()
		this.calcUpdatesCategories();
		this.calcPartnershipCategories();

		// if (this.pageData.announcements && this.pageData.announcements.length > 0)
		// {
		// 	for (let i = 0; i < this.pageData.announcements.length; i++)
		// 	{
		// 		let block = this.pageData.announcements[i];
		// 		if (block.block_type == "announcement_head")
		// 		{
		// 			// consola.debug('emiting showTopBanner: ', block);
		// 			// debugger;
		// 			this.setTopBanner(block)
		// 			// this.$nextTick( () => this.$root.$emit('showTopBanner', block) )
		// 			break;
		// 		}
		// 	}
		// }

		// consola.debug('partnersCategories: ', JSON.stringify( this.partnersCategories ) )

		// consola.debug('blocks[10].extra_data: ', this.blocks[10].extra_data)
		consola.debug('pageData: ', this.pageData)
		consola.debug('$parent', this.$parent)

		this.$nextTick( ()=>
		{
			setTimeout( ()=>
			{
				this.pageLoaded = true;
				if ( this.$refs.sidebar )
					this.$refs.sidebar.selected_idx = 0;
			}, 10 )
		} )

		this.heroSubtitleStyle.opacity = 0;

		setTimeout(
			() => {
				this.heroSubtitleStyle.opacity = 1;
				consola.debug('heroSubtitleStyle.opacity: ', this.heroSubtitleStyle.opacity)
			},
			1000
		)

		// this.isEditingPage = this._name == "<CorporateEdit>";
		this.isEditingPage = endsWith(this.$vnode.key, 'edit');

		this.$store.commit('setEditingPage', { editing: this.isEditingPage })

		// this.$meta().refresh();
	},

	beforeDestroy()
	{
		this.unregisterEvents()
	},

	methods:
	{
		...mapMutations( ['setTopBanner', 'setSiteData', 'setWhiteLogo'] ),

		log(msg)
		{
			consola.debug(msg)
		},

		blockIndex(block)
		{
			for (let i = 0; i < this.blocks.length; i++)
			{
				if (block == this.blocks[i])	return i;
				if (block.block_type == this.blocks[i].block_type)	return i;
			}

			return null;
		},

		registerEvents()
		{
			if ( typeof window !== 'undefined' )
			{
				this.resizeCallback = () => this.onWindowResize()
				window.addEventListener('resize', this.resizeCallback )
			}
			// this.$root.$on( 'show-quote-form', this.onShowQuoteForm )
			this.$eventBus.$on( 'rest:response', this.updateData )
			this.$eventBus.$on( 'set-page-data', this.setPageData )
		},

		unregisterEvents()
		{
			if ( typeof window !== 'undefined' )
			{
				if (this.resizeCallback)
					window.removeEventListener('resize', this.resizeCallback )
			}
			// this.$root.$off( 'show-quote-form', this.onShowQuoteForm )
			this.$eventBus.$off( 'rest:response', this.updateData )
			this.$eventBus.$off( 'set-page-data', this.setPageData )
		},

		onWindowResize()
		{
			this.calcAffixPos();
		},

		isIframeUrl(url)
		{
			if (!url)	return false;
			return (url.indexOf('iframe:') == 0)
		},

		openIframe(url, event)
		{
			if (!url)	return;
			url = url.replace('iframe:', '')

			if (event)
			{
				event.stopPropagation();
				event.preventDefault();
			}

			this.$root.$emit( 'show-external-form', url);
		},

		openBlockModal(event, item)
		{
			event && event.preventDefault();
			consola.debug('openBlockModal')
			this.$root.$emit('open-modal', {...item} );
		},

		updateData(modelResponse)
		{
			this.fetchData()
		},

		calcUpdatesCategories()
		{
			this.updatesCategories = [];
			if ( this.blocks[8] && this.blocks[8].extra_data )
			{
				const rnd = Math.round(Math.random()*10000)
				let idx = 0;
				for (let item of this.blocks[8].extra_data)
				{
					item.order = rnd + idx;
					idx++;
					if (item.taxonomies)
					{
						for (let tax of item.taxonomies)
						{
							const found = this.updatesCategories.find( x => x && x.taxonomy_id == tax.taxonomy_id);

							if ( !found )
							{
								this.updatesCategories = [ ...this.updatesCategories, {...tax} ];
							}
						}
					}
				}
			}
		},

		calcPartnershipCategories()
		{
			const pos = 10;
			this.partnersCategories = [];
			if ( this.blocks[pos] && this.blocks[pos].extra_data )
			{
				const rnd = Math.round(Math.random()*10000)
				let idx = 0;
				for (let item of this.blocks[pos].extra_data)
				{
					item.order = rnd + idx;
					idx++;
					if (item.taxonomies)
					{
						for (let tax of item.taxonomies)
						{
							const found = this.partnersCategories.find( x => x && x.taxonomy_id == tax.taxonomy_id);

							if ( !found )
							{
								this.partnersCategories = [ ...this.partnersCategories, {...tax} ];
							}
						}
					}
				}
			}
		},

		setPageData(data)
		{
			consola.debug('setPageData')

			let scrollY = 0;
			if (typeof window !=='undefined')
				scrollY = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;


			this.pageData = data;
			let menu = this.pageData.menu && this.pageData.menu.json || [];
			this.$store.commit('setPageMenu', menu);

			const footer = this.pageData.footer && this.pageData.footer.json || [];
			this.$store.commit('setPageFooter', footer);

			if (typeof window !=='undefined')
			{
				setTimeout( ()=>
				{
					if (document.documentElement.scrollTop)	document.documentElement.scrollTop = scrollY;
					if (window.pageYOffset)					window.pageYOffset = scrollY;
					if (document.body.scrollTop)			document.body.scrollTop = scrollY;
				}, 0);
			}
		},

		async fetchData()
		{
			// get the last path element
			const slug = window.location.pathname.replace('/edit', '').split('/').reduce( (_, x) => x )

			const isAcademy = window.location.hostname.indexOf('academy') > -1;
			let page_slug = this.$route.path.replace('/edit','');//.slice(3);
			if (!isAcademy)	page_slug = page_slug.slice(3);
			page_slug = page_slug || '/';

			// this.$apiGet('page-slug/corporate', { locale: this.lang } )
			this.$apiGet('page-slug/', { slug: page_slug, site_id: 1, locale: this.lang } )
				.then( (result) =>
				{
					consola.debug('Object.keys(result): ', Object.keys(result) );
					consola.debug('result.status: ', result.status );
					consola.debug('result.statusText: ', result.statusText );
					// consola.debug('result: ', result);

					this.setPageData(result.data[0]);
					// this.pageData = result.data[0]
					// // this.blocks = result.data[0].block_list
					// // consola.debug('pageData: ', result.data[0])

					// // this.calcUpdatesCategories();
					// // this.calcPartnershipCategories();
					// // this.$meta().refresh();
				})
				.catch( (error) =>
				{
					console.error(`${__filename}[498] error: `, error)
				} )
		},

		academyUrl(url)
		{
			if (this.host.indexOf('academy.') == -1)
				return `https://academy.${this.host}${url}`;
				else
				return `https://${this.host}${url}`;
		},

		capitalize(str)
		{
			return capitalize(str);
		},

		onIntersection(event)
		{
			consola.debug('onIntersection: ', event);
		},

		// onScrollerTick()
		// {
		// 	// consola.debug('tick');

		// },

		// onAffixVisibilityChange(event)
		// {
		// 	consola.debug('onAffixVisibilityChange: ', event[0]);
		// },


		statsBoxEnter(el, done)
		{
			consola.debug('statsBoxEnter')
			const self = this;
			setTimeout( ()=>
			{
				el.className += ' number-stats-enter-active number-stats-enter ';
				this.$nextTick( ()=>
				{
					el.className = el.className.replace('number-stats-enter ',  '');
					setTimeout( ()=>
					{
						done();
					}, 500 )
				} )

			}, 1000 )
		},

		statsBoxLeave(el, done)
		{
			consola.debug('statsBoxLeave')
			el.className += ' number-stats-leave-active number-stats-leave ';
			this.$nextTick( ()=>
			{
				el.className = el.className.replace('number-stats-leave ',  '');
				setTimeout( ()=>
				{
					done();
				}, 1500 )
			} )

		},

		calcAffixPos()
		{
			// consola.debug('calcAffixPos');

			if (this.isEditingPage)	return;

			if (this.isAffixTransitioning)	return;
			if (!this.$refs.brandmark || !this.$refs.affixRails || !this.$refs.stats || this.isMobile)
				return;
			let railsBox = JSON.parse(JSON.stringify( this.$refs.affixRails.getBoundingClientRect() ));
			let brandBox = JSON.parse(JSON.stringify( this.$refs.brandmark.getBoundingClientRect() ));
			let scrollingBox = JSON.parse(JSON.stringify( this.$refs.stats.getBoundingClientRect() ));

			// debugger;
			// railsBox.top -= this.scrollTop;
			// railsBox.bottom -= this.scrollTop;
			// brandBox.top -= this.scrollTop;
			// brandBox.bottom -= this.scrollTop;
			// scrollingBox.top -= this.scrollTop;
			// scrollingBox.bottom -= this.scrollTop;

			let topOffset = 0;
			// let menuEl = document.querySelector('.Menu');
			// if (menuEl)
			// {
			// 	let box = menuEl.getBoundingClientRect();
			// 	topOffset = box.bottom;
			// }
			topOffset = 140;

			// let topOffset = this.affixTopOffset;
			// let topOffset = 0;

			this.affixYPercent = (scrollingBox.top - railsBox.top) / (railsBox.height - scrollingBox.height);
			// consola.debug('affixYPercent: ', this.affixYPercent)

			if (this.$refs.numbersCarousel)
			{
				let slideIdx = Math.floor(this.affixYPercent * this.$refs.numbersCarousel.items.length);
				// debugger;
				this.$refs.numbersCarousel.goto(slideIdx);
			}

			let percent = Math.max(0, Math.min(this.affixYPercent, 1) );
			let idx = Math.floor( percent * (this.stickyScrollerBlock.extra_data.length + 2) );
			this.affixSlideIndex = Math.min( idx, this.stickyScrollerBlock.extra_data.length );

			// consola.debug(`railsBox.bottom[${railsBox.bottom}] scrollingBox.bottom[${scrollingBox.bottom}] `)
			// consola.debug(`railsBox.bottom[${railsBox.bottom}] bottom[${topOffset + scrollingBox.height}] `)
			// consola.debug(railsBox)
			// this.isAffixFixed = (railsBox.top < topOffset) && (railsBox.bottom > window.innerHeight) && !this.isAffixAtBottom;
			const isRailAboveTop = railsBox.top < topOffset;
			const isRailBelowBottom = railsBox.bottom >  topOffset + scrollingBox.height;
			this.isAffixFixed = isRailAboveTop && isRailBelowBottom;// && !this.isAffixAtBottom;
			this.affixTop = this.isAffixFixed ? topOffset : 0;
			let style = {
				top: `${this.isAffixFixed ? topOffset : 0}px`,
				// opacity: 1 - (-railsBox.top / railsBox.height)
			};

			if (this.isAffixFixed)
			{
				style.position = 'absolute';
			}

			if ( railsBox.bottom <  topOffset + scrollingBox.height )
			{
				style.top = 'unset';
				style.bottom = 0;
				style.position = 'absolute';
			}

			// consola.debug(`isRailAboveTop[${isRailAboveTop}] isRailBelowBottom[${isRailBelowBottom}] isAffixFixed[${isAffixFixed}]`)

			if (!this.isAffixAtBottom || this.isAffixFixed)
				this.affixStyle = style;
		},



		// called by tag-input
		updatePartnershipTags(event)
		{
			consola.debug('updatePartnershipTags')
			// this.$apiGetTaxonomy('PARTNERSHIP_CATEGORIES')
			// 	.then( (result) =>
			// 	{
			// 		// consola.debug('Object.keys(result): ', Object.keys(result) );
			// 		// consola.debug('result.status: ', result.status );
			// 		consola.debug('taxonomy[PARTNERSHIP_CATEGORIES] result: ', result);

			// 		if (result.data)
			// 		{
			// 			this.partnersCategories = result.data;
			// 		}
			// 		else
			// 		{
			// 			consola.debug('warning "PARTNERSHIP_CATEGORIES" returned empty...')
			// 			this.partnersCategories = []
			// 		}
			// 	})
			// 	.catch( (error) =>
			// 	{
			// 		console.error('error: ', error)
			// 	} )
		},

		// called by tag-input
		updateUpdatesTags(event)
		{
			consola.debug('updateUpdatesTags')
			// this.$apiGetTaxonomy('UPDATE_CATEGORIES')
			// 	.then( (result) =>
			// 	{
			// 		// consola.debug('Object.keys(result): ', Object.keys(result) );
			// 		// consola.debug('result.status: ', result.status );
			// 		consola.debug('taxonomy[UPDATE_CATEGORIES] result: ', result);

			// 		if (result.data)
			// 		{
			// 			this.updatesCategories = result.data;
			// 		}
			// 		else
			// 		{
			// 			consola.debug('warning "UPDATE_CATEGORIES" returned empty...')
			// 			this.updatesCategories = []
			// 		}

			// 	})
			// 	.catch( (error) =>
			// 	{
			// 		console.error('error: ', error)
			// 	} )
		},

		onChangeUpdatesCategory(cat)
		{
			if (!cat)	this.selectedUpdatesCatId = -1;
			else
			{
				if (this.selectedUpdatesCatId == cat.taxonomy_id)	return;
				this.selectedUpdatesCatId = cat.taxonomy_id;
			}

			// this.selected_update_cat = cat
			// this.$refs.carousel.refreshSlides()
			setTimeout(() =>
			{
				this.$refs.updatesCarousel.refreshSlides()
				// this.$refs.carousel.goto(0)
			}, 100 )
		},

		onChangePartnersCategory(cat)
		{
			if (!cat)	this.selectedPartnerCatId = -1;
			else
			{
				if (this.selectedPartnerCatId == cat.taxonomy_id)	return;
				this.selectedPartnerCatId = cat.taxonomy_id;
			}
			// this.selected_partners_cat = cat
			// this.$refs.partnersCarousel.refreshSlides()
			setTimeout(() =>
			{
				// this.$refs.partnersCarousel.gotoPage(0)
				this.$refs.partnersCarousel.refreshSlides()
			}, 100 )
		},

		onSwipeQuote(event)
		{
			// consola.debug('onSwipeQuote ', event)
			if ( Math.abs(event.overallVelocityX) > Math.abs(event.overallVelocityY) )
			{
				if (event.overallVelocityX < 0)
					this.gotoNextQuote()
				else
					this.gotoPrevQuote()
			}
		},


		gotoQuote(idx)
		{
			if (this.quoteIsTransitioning || this.quoteTransitionLeaving)	return;

			this.quoteIsTransitioning = true;
			this.quoteTransitionLeaving = true;
			const lines = this.$refs.quoteText.$el.querySelectorAll('.line-list .line');

			setTimeout( () =>
			{
				this.quoteIsTransitioning = false;
				this.quoteTransitionEntering = true;

				this.currentQuoteIdx = idx;

				setTimeout( () =>
				{
					this.quoteIsTransitioning = false;
					this.quoteTransitionLeaving = false;
					this.quoteTransitionEntering = false;
				}
				, lines.length * 200 )
			}
			, lines.length * 150 )
		},


		gotoPrevQuote()
		{
			if (this.quoteIsTransitioning || this.quoteTransitionLeaving)	return;

			this.quoteIsTransitioning = true;
			this.quoteTransitionLeaving = true;
			const lines = this.$refs.quoteText.$el.querySelectorAll('.line-list .line');

			setTimeout( () =>
			{
				this.quoteIsTransitioning = false;
				this.quoteTransitionEntering = true;

				// consola.debug('gotoPrevQuote');
				let idx = this.currentQuoteIdx - 1;
				if (idx < 0)
				{
					// idx = this.quotes.length - 1;
					idx = this.blocks[7].extra_data.length - 1;
				}
				this.currentQuoteIdx = idx;

				setTimeout( () =>
				{
					this.quoteIsTransitioning = false;
					this.quoteTransitionLeaving = false;
					this.quoteTransitionEntering = false;
				}
				, lines.length * 200 )
			}
			, lines.length * 150 )

		},

		gotoNextQuote()
		{
			if (this.quoteIsTransitioning || this.quoteTransitionLeaving)	return;

			this.quoteIsTransitioning = true;
			this.quoteTransitionLeaving = true;
			// debugger;
			const lines = this.$refs.quoteText.$el.querySelectorAll('.line-list .line');

			setTimeout( () =>
			{
				this.quoteIsTransitioning = false;
				this.quoteTransitionEntering = true;

				// consola.debug('gotoNextQuote');
				// let idx = (this.currentQuoteIdx + 1) % this.quotes.length;
				let idx = (this.currentQuoteIdx + 1) % (this.blocks[7].extra_data.length || 0);

				this.currentQuoteIdx = idx;

				setTimeout( () =>
				{
					// this.quoteIsTransitioning = false;
					this.quoteTransitionLeaving = false;
					this.quoteTransitionEntering = false;
				}
				, lines.length * 200 )
			}
			, lines.length * 150 )
		},

		clickAnalytics(item)
		{
			item.analytics_event && this.$gtag && this.$gtag.event( 'click', { event_category:'Link', event_label:  item.analytics_event, value:  1 });
			this.$fb && this.$fb.track('track', 'Contact');
		},

		showForm(item)
		{
			this.clickAnalytics(item)
			this.$root.$emit( 'show-external-form', item.url )
		},

		openArticle(item)
		{
			consola.debug('openArticle: ', item)
		},



	}
}
